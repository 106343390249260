import React from 'react'
import OutsideClickHandler from '../OutsideClickHandler'

export default class MultiSelect extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        /**
         * title: 显示的title，可为空
         * content: 文本内容，可以设置 html
         * children: 直接是React元素展现，用于文本不足以展现时候，设置 children 时候不需设置 content
         * extClass: 整个tip的根元素额外样式
         * childrenReplaceRoot: children是否包含整个root根元素
         */
        const { cabinList, selectCabinList, clickCabin, onOutsideClick } = this.props

        return (
            <OutsideClickHandler style={{ display: 'inline' }} onOutsideClick={onOutsideClick} >
                <ul>
                    {
                        cabinList.map(cabin =>
                            <li key={cabin.id} className={selectCabinList.indexOf(cabin.id) >= 0 ? 'active' : ''}>
                                {cabin.label}
                                <i className="ico-checkbox" onClick={() => clickCabin(cabin.id)}></i>
                            </li>
                        )
                    }
                </ul>
            </OutsideClickHandler>
        )
    }
}
