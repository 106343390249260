import React from "react"
import immutable from "immutable";
import MultiTrip from "./multiTrip"

const noop = ()=>{}
const getMtSegList = selectedCity => {
    let list = {}

    selectedCity && selectedCity.forEach((value, key) => {
        if (key.indexOf('mt') == 0) {
            let index = key.replace(/[^0-9]/ig, '') - 1
            let valueIsNotEmpty = value && (value._isAMomentObject || !value.isEmpty())
            if (!list[index]) {
                list[index] = {}
            }
            // let value = state.getIn(['list', 'search','selectedCity']).get(key)
            switch (key.substr(2, 1)) {
                case 'D':
                    if (value) {
                        list[index].dCityText = valueIsNotEmpty ? value.get('text') ? value.get('text') : `${value.get('Name')}(${value.get('Code')})` : ""
                        list[index].TimeZone = valueIsNotEmpty ? value.get('TimeZone') : ''
                    } else {
                        list[index].dCityText = ""
                        list[index].TimeZone = ""
                    }
                    break
                case 'A':
                    if (value) {
                        list[index].aCityText = valueIsNotEmpty ? value.get('text') ? value.get('text') : `${value.get('Name')}(${value.get('Code')})` : ""
                    } else {
                        list[index].aCityText = ""
                    }
                    break
                case 'S':
                    list[index].StartDate = value
                    break
            }
        }
    })
    return list
}

export default class MultiTripContainer extends React.Component {
    constructor(props) {
        super(props)
        this.onCityMouseDown = this.onCityMouseDown.bind(this)
        this.onDateMousDown = this.onDateMousDown.bind(this)
    }
    onCityMouseDown(_mode,_i) {
        this.props.onCleanMtError()
    }
    onDateMousDown() {
        this.props.onCleanMtError()
    }
    render() {
        //id是store中state的key值
        const { id, searchProps,workingDays,onAddMtSeg,onDelMtSeg,
               onSearchDateChange, focusMtSecondDCity,  exchangeMultiCity, handleEnter ,getCityText,onDateChange,
               changePoi,clearPoiItem,changeCountrySource,changeHotSearchSwitch,changeArrivalCountry,
               showWarn,cancelFocusMtSecondDCity,clickHotCountry,switchToHotCountryUbt
            } = this.props

        const selectedCity = searchProps.get('selectedCity')
        const isNeedFocusSecond = searchProps.get('isNeedFocusSecond')
        const flightWay = searchProps.get('flightWay')
        const mtError = searchProps.get('mtError')
        const showValidateErrorTip = searchProps.get('showValidateErrorTip')
        const canAddMoreMtSeg = searchProps.get('canAddMoreMtSeg')

        let handleDelMtSeg = i => _e => onDelMtSeg(i)
        let mtSegList = getMtSegList(selectedCity)
        
        const passProps = {
            selectedCity,
            mtSegList,
            getCityText,
            mtError,
            flightWay,
            isNeedFocusSecond,
            handleDelMtSeg,
            onDateChange,
            showValidateErrorTip,
            searchProps,
            onAddMtSeg,
            onSearchDateChange,
            focusMtSecondDCity,
            canAddMoreMtSeg,
            exchangeMultiCity,
            handleEnter,
            id,
            workingDays,
            changePoi,
            clearPoiItem,
            changeCountrySource,
            changeHotSearchSwitch,
            changeArrivalCountry,
            showWarn,
            cancelFocusMtSecondDCity,
            clickHotCountry,
            switchToHotCountryUbt,
            onCityMouseDown:this.onCityMouseDown,
            onDateMouseDown:this.onDateMousDown
        }

        return <MultiTrip {...passProps} />
    }
}

const searchProps = {
    flightWay: 'MT',
    selectedCity: {
        mtDCity1: null,
        mtACity1: null,
        mtStartDate1: null,
        mtDCity2: null,
        mtACity2: null,
        mtStartDate2: null
    },
    adultCnt: 1,
    childCnt: 0,
    infantCnt: 0,
    //search verification
    showValidateErrorTip: false,
    mtError: {},
    //POI
    poiKey: {},
    poiResult: {},
    poiListAdditionalInfo: {},
    lastPoi: [],
    poiCache: {},
    showCityPicker: {},
    showAreaCode: false,
    classGrade: [['Y']],
    //focus第二程
    isNeedFocusSecond: false,
    canAddMoreMtSeg: true,
    contact: {
        name: '',
        areaCode: '86',
        areaText: '中国大陆',
        telephone: '',
        email: '',
        note: ''
    },
    contactError: {
        nameError: '',
        telephoneError: '',
        emailError: ''
    },
    countryCodeList: []
}
MultiTripContainer.defaultProps = {
    onAddMtSeg:noop,
    onDelMtSeg:noop,
    onSearchDateChange:noop,
    focusMtSecondDCity:noop,
    exchangeMultiCity:noop,
    onDateChange:noop,
    clickHotCountry:noop,
    switchToHotCountryUbt:noop,
    handleEnter:noop,
    getCityText:noop,
    changePoi:noop,
    clearPoiItem:noop,
    changeCountrySource:noop,
    changeHotSearchSwitch:noop,
    changeArrivalCountry:noop,
    onChangeMtDCityError:noop,
    onChangeMtACityError:noop,
    onChangeMtDDateError:noop,
    onCleanMtError:noop,
    showWarn:noop,
    cancelFocusMtSecondDCity:noop,
    mtSegList:{},
    workingDays:{},
    searchProps:immutable.fromJS(searchProps)
}
