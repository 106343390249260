import React from 'react'
import shortid from 'shortid'
import { ExposeStat } from 'src/ubt.v2/exposeStat'
/**
 * 无结果大提示组件
 * @iconClassName: 搜索无结果推荐ico-noresult, 搜索无直飞推荐ico-nodirect
 * @label: text 很遗憾，未查询到指定机场航班/直飞航班/无直飞航班，为您推荐, 为您提供更多时间和航司选择，补充更多舱位航班
 * @cont: array 补充说明
 */
const NoResultTip = ({ ...props }) => {
    const { label, iconClassName, cont, demandFormControll } = props
    return (
      <ExposeStat ubtKey='S_Flt_I_List_demandform'>
        <div className='no-result demand-form-no-result'>
            <i className={iconClassName || "ico-noresult"}></i>
            <div className="title">
                <span dangerouslySetInnerHTML={{ __html: label}}></span>
                {demandFormControll || null}
                {cont ?
                <div className="cont">
                    {cont.map((c,index)=> {
                        return <p key={shortid.generate()}>{index + 1}. {c}</p>
                    })}
                </div>
                : null
            }
            </div>

        </div>
      </ExposeStat>
    )
}

export default NoResultTip
