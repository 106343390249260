/**
 * 选择乘客组件
 */
import React from 'react'
import Passenger from './passenger'

const SelectPassengersV2 = ({ warnType, adultCnt = 0, childCnt = 0, infantCnt = 0, decrementPassenger, incrementPassenger, warn, id, maxPassenger, isHint }) => {
    return [
        <Passenger key={id + 1} id={id} warn={warn} count={adultCnt} label="成人" isWarn={warnType === 'adult'} increment={() => incrementPassenger('adult', maxPassenger || 9, id)} decrement={() => decrementPassenger('adult', 0, id)} isHint={isHint} />,
        <Passenger key={id + 2} id={id} warn={warn} count={childCnt} label="儿童" isWarn={warnType === 'child'} increment={() => incrementPassenger('child', maxPassenger || 9, id)} decrement={() => decrementPassenger('child', 0, id)} isHint={isHint} />,
        <Passenger key={id + 3} id={id} warn={warn} count={infantCnt} label="婴儿" isWarn={warnType === 'infant'} increment={() => incrementPassenger('infant', maxPassenger || 4, id)} decrement={() => decrementPassenger('infant', 0, id)} isHint={isHint} />
    ]
}

export default SelectPassengersV2