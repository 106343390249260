import { UbtHandler } from '../../ubt.v2/handler'

const processLogin = (callback) => {
    if (typeof CLogin !== 'undefined' && CLogin.authBiz && CLogin.authBiz.isLogin) {
        CLogin.authBiz.isLogin({}, function (result) {
            // 0表示成功，510未登录，其他异常
            var returnCode = result.returnCode
            if (returnCode === 0) {
                callback()
            } else {
                UbtHandler.onUploadData('c_demand_login_show', {})
                CLogin.Mask.showView('', function () {
                    callback()
                },
                )
            }
        })
    } else {
        UbtHandler.onUploadData('c_demand_login_sdk_error', {})
        alert('页面数据加载失败，请重新加载。')
        location.reload()
    }
}
export default processLogin
