import React from 'react';
import NoResultTip from './noResultTip';
import DemandForm from './demandForm';
import GroupTitle from './groupTitle';

const SearchNoResult = ({ ...props }) => {
	const { label, showDemandForm, demandFormControll } = props,
		cont = [
			'提交国际及中国港澳台航班需求单，携程国际机票预订专家会尽快与您联系(时间9：00-21：00)',
			'联系在线客服（24小时）'
		];

	return (
		<div>
			<NoResultTip
				key="tip"
				iconClassName="ico-noresult"
				label={label}
				cont={showDemandForm ? cont : []}
				demandFormControll={demandFormControll}
			/>
			{showDemandForm ? (
				<React.Fragment>
					<GroupTitle iconClassName="ico-demand" title="国际及中国港澳台航班需求单" />
					<DemandForm key="demand" {...props} />
				</React.Fragment>
			) : null}
		</div>
	);
};

export default SearchNoResult;
