import React from 'react';
import { Tabs, TabList, Tab, TabPannels, TabPannel } from '../Tabs';
import Popover from '../poi/Popover';
import { AREA_CODE } from './enums';

const AreaCode = ({ ...props }) => {
  const { areaCode, areaText, countryCodeList, handleHideAreaCode } = props;

  const starts = AREA_CODE.STARTS.value,
    title = AREA_CODE.TITLE.value,
    hotCountryList = AREA_CODE.HOT_COUNTRY_LIST.value;

  let allCountryList = [],
    countryPlainList = { starts: starts, title: title, plain: {}, all: [] },
    pops;

  let classifyCode = () => {
    if (countryCodeList.length > 0) {
      allCountryList = countryCodeList.map(c => {
        return {
          text: c.name || '',
          code: c.code || '',
          pinYin: (c.pinyin || '').toUpperCase(),
          startsWith: (c.pinyin && c.pinyin.slice(0, 1)) || '',
        };
      });
      // 去掉热门城市不存在所有list中的城市
      for (let i = 0; i < hotCountryList.length; i++) {
        if (!allCountryList.some(c => c.code == hotCountryList[i].code)) {
          hotCountryList.splice(i, 1);
          i--;
        }
      }

      countryPlainList.starts.map(
        c =>
          (countryPlainList.plain[c] = allCountryList.filter(
            t => t.startsWith == c,
          )),
      );
      // countryPlainList.title.map(t => countryPlainList.plain[t] = allCountryList.filter(a => t.indexOf(a.startsWith) >= 0))
      starts.unshift('hot');
      countryPlainList.plain['hot'] = hotCountryList;

      countryPlainList.all = allCountryList;
    }
  };

  let clickAreaCode = (text, code) => {
    pops.handleCityPickerHide();
    handleHideAreaCode(text, code);
  };

  let tabContent = () => {
    classifyCode();

    if (countryPlainList.all.length > 0) {
      return (
        <div className="area-code-selector">
          <i className="ico-clear"></i>
          <Tabs tabBarStyle={{ padding: '2px 0px', margin: 0 }}>
            <TabList className="letter-tab">
              {title.map((t, k) => (
                <Tab
                  key={k}
                  activeClassName="active"
                  tabType="span"
                  className="tab"
                >
                  {t == 'hot' ? '热门' : t}
                </Tab>
              ))}
            </TabList>
            <TabPannels>
              {title.map((t, ck) => {
                let start = t == 'hot' ? ['hot'] : t.split('');
                return (
                  <TabPannel key={ck} className="cities">
                    {start.map((s, q) => {
                      return (
                        <div className="area-code-wrapper" key={q}>
                          <div
                            className={`area-code-group ${
                              s == 'hot' ? '' : 'has-sidebar'
                            }`}
                          >
                            {t == 'hot' ? null : (
                              <div className="sidebar-letter">{s}</div>
                            )}
                            {countryPlainList.plain[`${s}`].map((c, i) => {
                              return (
                                <div
                                  className="area-code"
                                  onClick={() => clickAreaCode(c.text, c.code)}
                                  key={i}
                                >
                                  <span className="country">{c.text}</span>
                                  <span className="code">{c.code}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </TabPannel>
                );
              })}
            </TabPannels>
          </Tabs>
        </div>
      );
    }
  };

  return (
    <Popover
      style={{ display: 'inline' }}
      renderDropdownContent={() => tabContent()}
      ref={pop => (pops = pop)}
      selectorStyle={{ position: 'absolute', left: 0, top: 51, zIndex: 9 }}
    >
      <div className="form-select">
        <div className="form-select-txt">
          <span className="area-name">{areaText}</span>
          <span className="area-code">+{areaCode}</span>
          <i className="arrow-down-bold"></i>
        </div>
      </div>
    </Popover>
  );
};

export default AreaCode;
