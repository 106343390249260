import React from 'react';
import { noop } from '../../../utils/commonUtils';

import Poi from './async';

const PoiContainer = (props) => {
	return <Poi {...props} />;
};

export default PoiContainer;

PoiContainer.defaultProps = {
	changePoi: noop,
	clearPoiItem: noop,
	changeCountrySource: noop,
	changeHotSearchSwitch: noop,
	changeArrivalCountry: noop,
	showWarn: noop,
	cancelFocusMtSecondDCity: noop,
	clickHotCountry: noop,
	switchToHotCountryUbt: noop,
	onMouseDown: noop,
};
