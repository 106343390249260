import React from "react";
import OutsideClickHandler from "../OutsideClickHandler";

const selectorStyle = { position: "absolute", left: 0, top: 30 };

export default class Popover extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPopover: false
        };

        this.handleCityPicker = this.handleCityPicker.bind(this);
        this.handleCityPickerHide = this.handleCityPickerHide.bind(this);
        this.onOutsideClick = this.onOutsideClick.bind(this);
        this.select = this.select.bind(this);
    }

    select() {
        this.textInput && this.textInput.select();
    }

    handleCityPicker(e) {
        // this.select();
        this.setState({ showPopover: !this.state.showPopover });
    }

    handleCityPickerHide(e) {
        if (this.props.onClose && this.state.showPopover === true) {
            this.props.onClose()
        }
        this.state.showPopover && this.setState({ showPopover: false });
    }

    ensureVisible() {
        if (this.state.showPopover === false) {
            this.setState({ showPopover: true });
        }
    }

    handleCityPickerShow(e) {
        this.setState({ showPopover: true });
    }

    onOutsideClick(e) {
        if (this.props.onOutsideClick && this.state.showPopover === true) {
            this.props.onOutsideClick()
        }
        this.handleCityPickerHide(e);
        return true;
    }

    render() {
        const {
            className,
            inputClass,
            placeholder,
            dropdownClassName,
            renderDropdownContent,
            style,
            data
        } = this.props;

        const children = React.Children.map(this.props.children, child => {
            return child ? React.cloneElement(child, { onClick: child.props.onClick ? (ev) => { child.props.onClick(ev); this.handleCityPicker(ev); } : this.handleCityPicker }) : null
        });

        return (
            <OutsideClickHandler style={style} onOutsideClick={this.onOutsideClick} className={className}>
                {children}
                {this.state.showPopover ?
                    <div className={dropdownClassName} style={this.props.selectorStyle || selectorStyle}>
                        {renderDropdownContent ? renderDropdownContent(data) : null}
                    </div> : null}
            </OutsideClickHandler>
        );
    }
}
