import React from 'react'

/**
 * Tabs组件
 *

使用如下:
import {
    Tabs,
    TabList,
    Tab,
    TabPannels,
    TabPannel
} from '../../common/Tabs'

const __UED = {
    title: {
        margin: '20px 0 0',
        textAlign: 'center'
    },
    tooltipMargin: {
        margin: '30px 10px',
        verticalAlign: 'top',
        textAlign: 'left'
    }
};
<div className="tooltip tooltip-table" style={UED.tooltipMargin}>
    <Tabs>
        <TabList>
            <Tab>去程</Tab>
            <Tab>返程</Tab>
            <Tab>多程</Tab>
        </TabList>
        <TabPannels>
            <TabPannel>
                <p>pannel 1</p>
            </TabPannel>

            <TabPannel>
                <p>pannel 2</p>
            </TabPannel>
            <TabPannel>
                <p>pannel 3</p>
            </TabPannel>
        </TabPannels>
    </Tabs>
</div>

 */

const defaultOnActive = () => { }

const TabList = ({ ...props }) => {
    const { activeIndex, className, tabListType } = props
    const children = React.Children.map(props.children, (child, index) => {
        return React.cloneElement(child, {
            isActive: index === activeIndex,
            onActivate: props.onActiveTab ? () => {
                props.onActiveTab(index)
            } : defaultOnActive
        })
    })

    return React.createElement(
        tabListType || 'div',
        { className: className || "tooltip-tab" },
        children
    )
}

const Tab = ({ ...props }) => {
    // const isDisable = false
    const { isActive, children, tabType, activeClassName, onActivate, className, disable, onTabActive, u_key, u_remark } = props
    let handelTabActivate = () => {
        onActivate()
        if (onTabActive) {
            onTabActive(children)
        }
    }
    return React.createElement(
        tabType || 'a',
        {
            className: isActive ? ((className || '') + ' ' + (activeClassName || "current")) : disable ? ((className || '') + ' ' + "disable") :
                (className || ''),
            onClick: disable ? null : handelTabActivate,
            u_key,
            u_remark
        },
        children
    )
}

const TabPannels = ({ ...props }) => {
    const { activeIndex, className } = props,
        children = props.children,
        activePanel = children[activeIndex] || (typeof children.get === 'function' ? children.get(activeIndex) : null)

    return <div className={className || ''}>{activePanel}</div>
}

const TabPannel = ({ ...props }) => {
    const { children, className, tabListType } = props
    return React.createElement(
        tabListType || 'div',
        { className: className || '' },
        children
    )
}

class Tabs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeIndex: this.props.activeIndex || 0
        }
    }

    onActiveTab(activeIndex) {
        this.setState({ activeIndex })
    }

    mapNestedChildren(parent) {
        let children = parent && parent.props && parent.props.children
        if (children) {
            return React.Children.map(children, child => {
                if (React.isValidElement(child)) {
                    if (child.type === TabList) {
                        return React.cloneElement(child, {
                            activeIndex: this.state.activeIndex,
                            onActiveTab: this.onActiveTab.bind(this),
                            children: child.props.dangerouslySetInnerHTML ? undefined : this.mapNestedChildren(child)
                        })
                    } else {
                        return React.cloneElement(child, {
                            children: child.props.dangerouslySetInnerHTML ? undefined : this.mapNestedChildren(child)
                        })
                    }
                } else {
                    return child
                }
            })
        } else {
            return parent
        }
    }

    render() {
        const children = React.Children.map(this.props.children, child => {
            if (child.type === TabPannels) {
                //考虑到一种奇特的需求：tabPanel 里面包含 tabList
                // nestTabList
                let nestTabList = child.props.nestTabList

                if (nestTabList) {
                    return React.cloneElement(child, {
                        activeIndex: this.state.activeIndex,
                        children: this.mapNestedChildren(child)
                    })
                } else {
                    return React.cloneElement(child, {
                        activeIndex: this.state.activeIndex,
                        onActiveTab: this.onActiveTab.bind(this)
                    })
                }
            } else if (child.type === TabList) {
                return React.cloneElement(child, {
                    activeIndex: this.state.activeIndex,
                    onActiveTab: this.onActiveTab.bind(this)
                })
            } else {
                return child
            }
        })
        return <div className={this.props.className || ''}>{children}</div>
    }
}

export {
    Tabs,
    TabList,
    Tab,
    TabPannels,
    TabPannel
}
