import React from 'react'
import { connect } from 'react-redux'
import Warn from '../../../../components/list/top/search/warn'
import SearchNoResult from '../../../../components/Base/searchNoResult'
import { getModifyTopInfo, getCityText, onDateChange } from '../../../../components/list/top/search'
import { multiTripSearchValidate } from '../../../../utils/checkValidate'

import {
    getTicketRequest, fetchCountryCodes, genInputContact, genChangeNameError,
    genChangeTelephoneError, genChangeEmialError, genShowAreaCode, genClickCabin, fetchRequireForm
} from '../../../../actions/list/recommend.js'
import {
    genChangeMtDCityError, 
    genChangeMtACityError, 
    genChangeMtDDateError,
    genCleanMtError, 
    genCleanOwRtError,
    genAddMtSeg,
    genDelMtSeg,
    genFocusMtSecondDCity,
    genSelectPOIItem
} from '../../../../actions/channel/channel'
import DEMAND_FORM_MSG from '../../../../constants/list/enums/demandForm'
import { searchNoResultRecommendSelector } from './searchNoResultRecommendSelector'
import { prevCondSelector } from '../flight/baseSelector'
import { LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list';
import {
    genOnSearchDateChange,
    genExchangeMultiCity,
    genShowWarn, 
    genCancelFocusMtSecondDCity,
    genIncrementPassenger,
    genDecrementPassenger
} from "../../../../actions/list/search"

import {
    genSwitchHotSearch, genChangeArrivalCountry, genChangeCountrySource
} from "../../../../actions/list/hotCountry"

import listUbt from "../../../../ubt/listUbt.js"


import Lib from "../../../../utils/lib"
import * as ENUM from '../../../../constants/channel/enum'
import processLogin from '../../../../sources/common/loginHandler'
import CONSTS from '../../../../constants/list/constConfig'

class DemandFormContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            contact: {
                name: '',
                areaCode: '86',
                areaText: '中国大陆',
                telephone: '',
                email: '',
                note: ''
            },
            focusInput: '',
            showCabinList: false,
            showDemandForm: false
        }
        this.handleInputContact = this.handleInputContact.bind(this)
        this.handleBlur = this.handleBlur.bind(this)
        this.handleFocus = this.handleFocus.bind(this)
        this.handleShowAreaCode = this.handleShowAreaCode.bind(this)
        this.handleHideAreaCode = this.handleHideAreaCode.bind(this)
        this.clickCabin = this.clickCabin.bind(this)
        this.toogleCabinList = this.toogleCabinList.bind(this)
        this.hideCabinList = this.hideCabinList.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.getLabel = this.getLabel.bind(this)
        this.saveForm = this.saveForm.bind(this)
        this.setShowDemanForm = this.setShowDemanForm.bind(this)
        this.getDemanFormControll = this.getDemanFormControll.bind(this)
    }

    componentDidMount() {
        let { prevCond, searchNoResultRecommend, getTicketRequest, getcountryCodeLists } = this.props,
            searchResult = getModifyTopInfo(prevCond)

        getTicketRequest(searchResult)
        getcountryCodeLists()
        // 埋点
        if (searchNoResultRecommend) LIST_UBT_KEY_TYPES.SEARCH_NO_RESULT.value.invoke(true)
    }

    toogleCabinList() {
        this.setState({ showCabinList: !this.state.showCabinList })
    }

    hideCabinList() {
        this.setState({ showCabinList: false })
        return true
    }

    handleInputContact(event) {
        let newState = this.state.contact
        newState[event.target.name] = event.target.value
        this.setState({
            contact: newState
        })
    }

    handleFocus(event) {
        this.setState({ focusInput: event.target.name })
    }
    setShowDemanForm() {
        this.setState({ showDemandForm: true })
    }

    handleBlur(event) {
        this.setState({ focusInput: '' })
        if (event.target.name == 'telephone') {

            let telephone = this.state.contact.telephone.trim()
            if (telephone) {
                let areaCode = this.props.ticketRequest.getIn(['contact', 'areaCode'])
                if (!(/^\d+$/.test(telephone))) {
                    this.props.onChangeTelephoneError(DEMAND_FORM_MSG.PHONE.value.FOREIGN_ILLEGAL)
                    return false;
                }
                if (areaCode == '86' && (!(/^1\d{10}$/.test(telephone)) || telephone.length < 11)) {
                    this.props.onChangeTelephoneError(DEMAND_FORM_MSG.PHONE.value.NOT_CN_TIP)
                    return false;
                }
            }
            this.props.onChangeTelephoneError()
        }
        else if (event.target.name == 'email') {
            let email = this.state.contact.email.trim()
            if (email) {
                if (!/^[^@\s]+@[^@\.\s]+(\.[^@\.\s]+)+$/.test(email)) {
                    this.props.onChangeEmialError(DEMAND_FORM_MSG.EMAIL.value.OPTIONAL_ILLEGAL)
                    return false;
                }
                this.props.onChangeEmialError()
            }
        }
        else if (event.target.name == 'name') {
            let name = this.state.contact.name.trim()
            if (name) {
                this.props.onChangeNameError()
            }
        }
        this.props.onInputContact(this.state.contact)
    }

    handleShowAreaCode() {
        this.props.onShowAreaCode()
    }

    handleHideAreaCode(text, code) {
        this.props.onHideAreaCode(text, code)
        let newState = this.state.contact,
            telephone = this.state.contact.telephone.trim()
        newState['areaCode'] = code
        this.setState({
            contact: newState
        })
        if (telephone) {
            if (!(/^\d+$/.test(telephone))) {
                this.props.onChangeTelephoneError(DEMAND_FORM_MSG.PHONE.value.FOREIGN_ILLEGAL)
                return false;
            }
            if (code == '86' && (!(/^1\d{10}$/.test(telephone)) || telephone.length < 11)) {
                this.props.onChangeTelephoneError(DEMAND_FORM_MSG.PHONE.value.NOT_CN_TIP)
                return false;
            }
        }
        this.props.onChangeTelephoneError()
    }

    clickCabin(id) {
        this.props.onClickCabin(id)
    }

    getPassengerWarn(ticketRequest) {
        let adultCnt = ticketRequest.get('adultCnt'),
            childCnt = ticketRequest.get('childCnt'),
            infantCnt = ticketRequest.get('infantCnt'),
            msgPart = null,
            warnStyle = { left: '86px' }

        if (adultCnt == 0 && childCnt == 0 && infantCnt == 0) {
            msgPart = '请选择至少选择一个出行人数'
        }

        if (msgPart) {
            return <Warn msgPart1={msgPart} style={warnStyle} />
        }

        return null
    }

    getCabinWarn(classGrade) {
        if (classGrade.size == 0) {
            return '请至少选择一个舱等'
        }

        return null

    }

    CheckRequireForm() {
        let ticketRequest = this.props.ticketRequest,
            contact = ticketRequest.get('contact'),
            flag = true,
            res = multiTripSearchValidate(ticketRequest, 'ticketRequest', this.props),
            passengerWarn = this.getPassengerWarn(ticketRequest),
            cabinWarn = this.getCabinWarn(ticketRequest.get('classGrade'))

        flag = res.mainIsValid

        if (passengerWarn) {
            flag = false
        }

        if (cabinWarn) {
            flag = false
        }

        if (!contact.get('name').trim()) {
            this.props.onChangeNameError(DEMAND_FORM_MSG.NAME.value.NOT_NULL)
            flag = false
        }
        if (!contact.get('telephone').trim()) {
            this.props.onChangeTelephoneError(DEMAND_FORM_MSG.PHONE.value.NOT_NULL)
            flag = false
        }
        if (!contact.get('email').trim()) {
            this.props.onChangeEmialError(DEMAND_FORM_MSG.EMAIL.value.NOT_NULL)
            flag = false
        }
        return flag
    }
    saveForm() {
        let ticketRequest = this.props.ticketRequest,
            contact = ticketRequest.get('contact'),
            selectedCity = this.props.ticketRequest.get('selectedCity'),
            flightSegments = [],
            length = 0
        for (let i = 0; i < 10; i++) {
            if (!selectedCity.getIn([`mtDCity${i + 1}`, 'Code'])) {
                length = i
                break
            }
        }

        for (let i = 0; i < length; i++) {
            flightSegments.push({
                'arrivalCityCode': selectedCity.getIn([`mtACity${i + 1}`, 'Code']),
                'departureCityCode': selectedCity.getIn([`mtDCity${i + 1}`, 'Code']),
                'departureDate': selectedCity.get(`mtStartDate${i + 1}`).format('YYYY-MM-DD')
            })
        }

        let params = {
            'cabinList': ticketRequest.get('classGrade'),
            'flightSegments': flightSegments,
            'adultCount': ticketRequest.get('adultCnt'),
            'childCount': ticketRequest.get('childCnt'),
            'infantCount': ticketRequest.get('infantCnt'),
            'name': contact.get('name'),
            'cell': contact.get('telephone'),
            'cellCountryCode': contact.get('areaCode'),
            'email': contact.get('email'),
            'note': contact.get('note')
        }
        this.props.saveRequireForm(params)
    }

    handleSubmit(event) {
        let isCorrect = this.CheckRequireForm()
        if (isCorrect) {
            processLogin(this.saveForm)
        }
        else {
            event.stopPropagation()
        }

    }
    getLabel() {
        let { prevCond, flightWay } = this.props;
        let tipsSegment = prevCond.getIn(['flightSegments', 0]),
            dCityText = tipsSegment.get('departureAirportName') ?
                `${tipsSegment.get('departureCityName')}(${tipsSegment.get('departureAirportName')})(${tipsSegment.get('departureCityCode')})`
                : `${tipsSegment.get('departureCityName')}(${tipsSegment.get('departureCityCode')})`,
            segment = `${Lib.filterNumberInPoiText(dCityText)}`

        if (flightWay === 'M') {
            return CONSTS.SEARH_NO_RESULT.WARN_CONTEN.text.replace(/\[segment\]/, segment + '出发').replace(/\[flightWay\]/, '多程')

        }
        else {
            let aCityText = tipsSegment.get('arrivalAirportName') ?
                `${tipsSegment.get('arrivalCityName')}(${tipsSegment.get('arrivalAirportName')})(${tipsSegment.get('arrivalCityCode')})`
                : `${tipsSegment.get('arrivalCityName')}(${tipsSegment.get('arrivalCityCode')})`,
                startDateText = tipsSegment.get('departureDate'),
                segment = ` ${Lib.filterNumberInPoiText(dCityText)} 至 ${Lib.filterNumberInPoiText(aCityText)}（出发日期：${startDateText}）`
            return CONSTS.SEARH_NO_RESULT.WARN_CONTEN.text.replace(/\[segment\]/, segment).replace(/\[flightWay\]/, '')
        }

    }
    getDemanFormControll() {
        return <a className='appy-demand-form-link' onClick={this.setShowDemanForm} dangerouslySetInnerHTML={{ __html: CONSTS.SEARH_NO_RESULT.DEMAND_FORM_LINK.text}}></a>
    }

    render() {
        const { ticketRequest, incrementPassenger, flightWay,
            searchNoResultRecommend, prevCond, workingDays,
            changePoi, clearPoiItem, changeCountrySource,
            showWarn, changeArrivalCountry, cancelFocusMtSecondDCity,
            onAddMtSeg, onDelMtSeg, onSearchDateChange, focusMtSecondDCity,
            exchangeMultiCity, decrementPassenger, clickHotCountry, switchToHotCountryUbt, onCleanMtError
        } = this.props
        const areaCode = ticketRequest.getIn(['contact', 'areaCode']),
            areaText = ticketRequest.getIn(['contact', 'areaText']),
            countryCodeList = ticketRequest.get('countryCodeList'),
            showAreaCode = ticketRequest.get('showAreaCode')

        let passengerWarn = this.getPassengerWarn(ticketRequest),
            cabinWarn = this.getCabinWarn(ticketRequest.get('classGrade'))


        return (
            searchNoResultRecommend ?
                <SearchNoResult
                    ticketRequest={ticketRequest}
                    searchProps={ticketRequest}
                    workingDays={workingDays}
                    prevCond={prevCond}
                    areaCode={areaCode}
                    areaText={areaText}
                    focusInput={this.state.focusInput}
                    countryCodeList={countryCodeList}
                    flightWay={flightWay}
                    handleInputContact={this.handleInputContact}
                    handleBlur={this.handleBlur}
                    handleFocus={this.handleFocus}
                    handleCityPickerMouseDown={this.handleCityPickerMouseDown}
                    contact={this.state.contact}
                    showAreaCode={showAreaCode}
                    handleShowAreaCode={this.handleShowAreaCode}
                    handleHideAreaCode={this.handleHideAreaCode}
                    handleSubmit={this.handleSubmit}
                    passengerWarn={passengerWarn}
                    cabinWarn={cabinWarn}
                    clickCabin={this.clickCabin}
                    toogleCabinList={this.toogleCabinList}
                    showCabinList={this.state.showCabinList}
                    onOutsideClick={this.hideCabinList}
                    changePoi={changePoi}
                    clearPoiItem={clearPoiItem}
                    changeCountrySource={changeCountrySource}
                    showWarn={showWarn}
                    changeArrivalCountry={changeArrivalCountry}
                    cancelFocusMtSecondDCity={cancelFocusMtSecondDCity}
                    onAddMtSeg={onAddMtSeg}
                    onDelMtSeg={onDelMtSeg}
                    onSearchDateChange={onSearchDateChange}
                    focusMtSecondDCity={focusMtSecondDCity}
                    exchangeMultiCity={exchangeMultiCity}
                    clickHotCountry={clickHotCountry}
                    switchToHotCountryUbt={switchToHotCountryUbt}
                    incrementPassenger={incrementPassenger}
                    decrementPassenger={decrementPassenger}
                    getCityText={getCityText}
                    onDateChange={onDateChange}
                    onCleanMtError={onCleanMtError}
                    label={this.getLabel()}
                    showDemandForm={this.state.showDemandForm}
                    demandFormControll={this.getDemanFormControll()}
                /> :
                null
        )
    }
}

const mapStateToProps = (state) => ({
    ticketRequest: state.getIn(['list', 'ticketRequest']),
    flightWay: state.getIn(['list', 'search', 'prevCond', 'flightWay']),
    workingDays: state.getIn(['list', 'common', 'workingDays']),
    searchNoResultRecommend: searchNoResultRecommendSelector(state),
    prevCond: prevCondSelector(state)
})

const mapDispatchToProps = dispatch => ({
    getTicketRequest(searchResult) {
        dispatch(getTicketRequest(searchResult))
    },
    getcountryCodeLists() {
        dispatch(fetchCountryCodes())
    },
    onInputContact(contact) {
        dispatch(genInputContact(contact))
    },
    onChangeNameError(error) {
        dispatch(genChangeNameError(error))
    },
    onChangeTelephoneError(error) {
        dispatch(genChangeTelephoneError(error))
    },
    onChangeEmialError(error) {
        dispatch(genChangeEmialError(error))
    },
    onShowAreaCode() {
        dispatch(genShowAreaCode(true))
    },
    onHideAreaCode(text, code) {
        dispatch(genShowAreaCode(false, text, code))
    },
    onChangeMtDCityError(index, error, id) {
        dispatch(genChangeMtDCityError(index, error, id))
    },
    onChangeMtACityError(index, error, id) {
        dispatch(genChangeMtACityError(index, error, id))
    },
    onChangeMtDDateError(index, error, id) {
        dispatch(genChangeMtDDateError(index, error, id))
    },
    onCleanMtError(index) {
        dispatch(genCleanMtError(index, '', 'ticketRequest'))
    },
    onCleanOwRtError() {
        dispatch(genCleanOwRtError())
    },
    onClickCabin(id) {
        dispatch(genClickCabin(id))
    },
    saveRequireForm(params) {
        dispatch(fetchRequireForm(params))
    },
    changePoi(name, item, focusNextInput) {
        dispatch(genSelectPOIItem(name, item, 'ticketRequest', focusNextInput))
    },
    clearPoiItem(name) {
        dispatch(genSelectPOIItem(name, null, 'ticketRequest'))
    },
    changeCountrySource(countrySource) {
        dispatch(genChangeCountrySource(countrySource))
    },
    changeArrivalCountry(arrivalCity) {
        dispatch(genChangeArrivalCountry(arrivalCity))
    },
    showWarn() {
        dispatch(genShowWarn())
    },
    cancelFocusMtSecondDCity() {
        dispatch(genCancelFocusMtSecondDCity())
    },

    onAddMtSeg() {
        dispatch(genAddMtSeg('ticketRequest'))
    },

    onDelMtSeg(i) {
        dispatch(genDelMtSeg(+i, 'ticketRequest'))
    },

    onSearchDateChange(field, date, focusNextInput) {
        dispatch(genOnSearchDateChange(field, date, focusNextInput, 'ticketRequest'))
    },
    //focus第二程
    focusMtSecondDCity() {
        dispatch(genFocusMtSecondDCity('ticketRequest'))
    },
    exchangeMultiCity(index) {
        dispatch(genExchangeMultiCity(index, 'ticketRequest'))
    },

    incrementPassenger(type, max, id) {
        dispatch(genIncrementPassenger(type, max, id))
    },
    decrementPassenger(type, min, id) {
        dispatch(genDecrementPassenger(type, min, id))
    },
    clickHotCountry(hotCountry) {
        dispatch(genChangeArrivalCountry(hotCountry))
        dispatch(genSwitchHotSearch(true))
        dispatch(genChangeCountrySource(ENUM.COUNTRY_SOURCE.SUGGESTION))
        setTimeout(() => {
            listUbt.reportHotCountryToApi(hotCountry, 3)
        }, 500)
    },
    switchToHotCountryUbt(countryName) {
        setTimeout(() => { listUbt.reportHotCountryToApi(countryName, 1) }, 500)
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(DemandFormContainer)
