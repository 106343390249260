import Enum from 'enum'
/**
 * 需求单的舱等
 */

export const DEMAND_CABIN_DATA = new Enum({
    CLASSGRADE: [
        { id: 'Y', label: '经济舱', name: '经济' },
        { id: 'S', label: '超级经济舱', name: '超级经济' },
        { id: 'C', label: '公务舱', name: '公务' },
        { id: 'F', label: '头等舱', name: '头等' }
    ]
})