
/**
 * 城市组件
 */
import React from 'react'

import shallowCompare from "react-addons-shallow-compare"
import PoiContainer from './poi/poiContainer'

class CityV2 extends React.Component {
    constructor() {
        super()
        this.state = {
            isFocused: false
        }

        this.onFocus = this.onFocus.bind(this)
        this.onBlur = this.onBlur.bind(this)
    }

    onFocus() {
        this.setState({isFocused: true})
    }
    onBlur() {
        this.setState({isFocused: false})
    }

    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    render() {
        const {className, label, name, mode, cityText, errorMsg, warnMsg, flightWay, isFocused, ...restProps} = this.props;
        return (
            <div className={`form-item-v2 flt-${mode == 'A' ? 'arrival' : 'depart'} ${this.state.isFocused ? 'form-focused-v2' : ''} ${errorMsg ? 'form-error-v2' : (warnMsg ? 'form-warning-v2' : '')}`}
            style={{zIndex: this.state.isFocused ? '20' : 'inherit'}}>
                <i className={`ico-${mode == 'A' ? 'arrival' : 'depart'}-plane`}></i>
                <PoiContainer
                    mode={mode || 'D'} name={name} cityText={cityText}
                    currentFlightWay={flightWay} errorMsg={errorMsg}
                    warnMsg={warnMsg} label={label} onFocus={this.onFocus} onBlur={this.onBlur} {...restProps} />
            </div>
        )
    }
}
    
export default CityV2
