import Enum from 'enum'
import mergeConfig from '../../common/mergeConfig'
import Q_CONFIG_KEYS from '../qConfigKeys'

// hover文案默认值
const DEMAND_FORM_MSG = new Enum({
    NAME: {
        NOT_NULL: '请填写姓名'
    },
    PHONE: {
        NOT_NULL: '请填写手机号码',
        ILLEGAL: '请填写正确的手机号码，以便接收航班动态信息',
        CN_ILLEGAL: '请填写正确的手机号码，以便接收航班动态信息',
        FOREIGN_ILLEGAL: '请填写正确的手机号码，以便接收航班动态信息',
        NOT_CN_TIP: '您填写的号码非大陆11位手机号，可能影响您正常获取联系短信，如确认无误，可继续下单'
    },
    EMAIL: {
        OPTIONAL_ILLEGAL: '请填写正确的Email地址，格式：a@b.c',
        NOT_NULL: '请填写Email地址'
    }
})

export default mergeConfig(Q_CONFIG_KEYS.DEMAND_FORM_MSG, DEMAND_FORM_MSG)