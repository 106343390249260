import React from 'react'
import AreaCode from '../areaCodeSelector/areaCode'
import MultiTrip from '../multiTrip'
import MultiSelect from './multiSelect'
import SelectPassengers from '../selectPassengers'
import { DEMAND_CABIN_DATA } from './enums'
// import { InfantTip, ChildTip } from '../../top/search/childInfantTip'
// import ReactToolTip from '../reactToolTip'
import { popNextFormItem } from '../../../utils/commonUtils'

let elForm;
const DemandForm = ({ ...props }) => {
    const { ticketRequest, areaCode, areaText, countryCodeList, handleInputContact, handleBlur, handleFocus, focusInput,
        contact, showAreaCode, handleShowAreaCode, handleHideAreaCode, handleSubmit, passengerWarn, cabinWarn, clickCabin,
        toogleCabinList, showCabinList, onOutsideClick,DEMAND_FORM_DATA = DEMAND_CABIN_DATA, incrementPassenger, decrementPassenger} = props
    const showValidateErrorTip = ticketRequest.get('showValidateErrorTip'),
        nameError = ticketRequest.getIn(['contactError', 'nameError']),
        telephoneError = ticketRequest.getIn(['contactError', 'telephoneError']),
        emailError = ticketRequest.getIn(['contactError', 'emailError']),
        classGrade = ticketRequest.get('classGrade'),
        adultCnt =  ticketRequest.get('adultCnt'),
        childCnt =  ticketRequest.get('childCnt'),
        infantCnt =  ticketRequest.get('infantCnt')
    let formCabinSelect = DEMAND_FORM_DATA.CLASSGRADE.value.filter(cabin => classGrade.indexOf(cabin.id) >= 0).map(cabin => cabin.name),
        formSelectTxt = formCabinSelect.length > 0 ? `${formCabinSelect.join(' / ')}舱` : '',
        handleEnter = el => {
            elForm && popNextFormItem(el, elForm);
        };

    return (
        <div className="new-demand">
            <div className="newdmd-form" ref={dmdform => { elForm = dmdform }}>
                <MultiTrip id="ticketRequest" {...props} handleEnter={handleEnter}/>
                <div className="more-fields">
                    <SelectPassengers id='ticketRequest'
                        adultCnt={adultCnt} childCnt={childCnt} infantCnt={infantCnt}
                        maxPassenger={999} warn={passengerWarn} warnType={passengerWarn ? 'adult' : null}
                        incrementPassenger={incrementPassenger} decrementPassenger={decrementPassenger} />
                    <div className="flt-field">
                        <span className="caption">舱位</span>
                        <div className={`form-item-v2 flt-subclass ${cabinWarn ? 'form-error-v2' : ''}`}>
                            <div className="form-select-v2" onClick={toogleCabinList}>
                                <span className="form-select-txt">{formSelectTxt}</span><i className="arrow-t-down-l"></i>
                            </div>
                            {cabinWarn ? <div className="form-error-msg">{cabinWarn}</div> : null}
                            <div className="form-select-list multi" style={{ display: showCabinList ? 'block' : 'none' }}>
                                <MultiSelect onOutsideClick={onOutsideClick} cabinList={DEMAND_FORM_DATA.CLASSGRADE.value} selectCabinList={classGrade} clickCabin={clickCabin} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-form">
                <div className="contact-tit">联系人信息</div>

                <div className="form-line">
                    <div className={`form-item contact-name ${showValidateErrorTip && nameError ? 'form-error' : ''} ${focusInput == 'name' ? 'form-focused' : ''}`}>
                        <input type="text" className={`form-input ${contact.name ? 'hasvalue' : ''}`}
                            name="name" value={contact.name}
                            onChange={handleInputContact}
                            onFocus={(e) => handleFocus(e)}
                            onBlur={(e) => handleBlur(e)} />
                        <label className="form-input-hint">姓名</label>
                        {showValidateErrorTip && nameError ? <div className="form-error-msg">{nameError}</div> : null}
                    </div>
                    <div className={`form-item contact-phone ${showValidateErrorTip && telephoneError ? 'form-error' : ''} ${focusInput == 'telephone' ? 'form-focused' : ''}`} >
                        <AreaCode
                            areaCode={areaCode} areaText={areaText} countryCodeList={countryCodeList} showAreaCode={showAreaCode}
                            handleShowAreaCode={handleShowAreaCode} handleHideAreaCode={handleHideAreaCode} />

                        <input type="text" className={`form-input ${contact.telephone ? 'hasvalue' : ''}`}
                            name="telephone" value={contact.telephone}
                            onChange={(e) => handleInputContact(e)}
                            onFocus={(e) => handleFocus(e)}
                            onBlur={(e) => handleBlur(e)} />
                        <label className="form-input-hint">手机号码</label>
                        {showValidateErrorTip && telephoneError ? <div className="form-error-msg">{telephoneError}</div> : null}
                    </div>
                    <div className={`form-item contact-email ${showValidateErrorTip && emailError ? 'form-error' : ''} ${focusInput == 'email' ? 'form-focused' : ''}`}>
                        <input type="text" className={`form-input ${contact.email ? 'hasvalue' : ''}`}
                            name="email" value={contact.email}
                            onChange={handleInputContact}
                            onFocus={(e) => handleFocus(e)}
                            onBlur={(e) => handleBlur(e)} />
                        <label className="form-input-hint">电子邮箱</label>
                        {showValidateErrorTip && emailError ? <div className="form-error-msg">{emailError}</div> : null}
                    </div>
                </div>
                <div className="form-line">
                    <div className="form-item remarks">
                        <textarea placeholder="备注（可补充填写更多出行要求）"
                            className="textarea"
                            name="note" value={contact.note}
                            onChange={handleInputContact}
                            onBlur={(e) => handleBlur(e)}></textarea>
                    </div>
                </div>
            </div>

            <div className="newdmd-submit">
                <a href="javascript:;" className="btn btn-book" onClick={(e) => handleSubmit(e)}>提交需求单</a>
            </div>

            {/* <ReactToolTip parentId="childTip_ticketRequest" toolTipContent={<ChildTip />} delayTime={1} /> */}
            {/* <ReactToolTip parentId="infantTip_ticketRequest" toolTipContent={<InfantTip />} delayTime={1} /> */}

        </div>
    )
}

export default DemandForm
