/**
 * 乘客组件
 */
import React from 'react'

const PassengerV2 = ({ className, label, count, decrement, increment, isWarn, warn, isHint, id }) => {
    let tooltipId = ''
    if (label == '婴儿')
        tooltipId = `infantTip_${id}`
    else if (label == '儿童')
        tooltipId = `childTip_${id}`
    return (
        <div className={className || "flt-field"}>
            <span id={tooltipId} className={`caption ${tooltipId ? 'abbr' : ''}`}>{label || ''}</span>
            <div className={`form-item-v2 ${isWarn ? `form-${isHint ? 'warning' : 'error'}-v2` : ''}`}>
                <div className="passenger-num">
                    <span u_key='minus_passenger_count' u_remark={`搜索框减少${label}乘客数量按钮[点击前数量${count}]`} className="minus" onClick={decrement}><i className="ico-minus"></i></span>
                    {~~count || 0}
                    <span u_key='plus_passenger_count' u_remark={`搜索框增加${label}乘客数量按钮[点击前数量${count}]`} className="plus" onClick={increment}><i className="ico-plus"></i></span>
                </div>
                {isWarn ? warn : undefined}
            </div>
        </div>
    )
}


export default PassengerV2
