/**
 * 多程组件
 */
import React from "react"
import moment from "moment"
import City from "../city"
import ModifyDateInfo from "../modifyDateInfo"
import { DATE_PICKER_TITLE, DATE_PICKER_FOOTER_TIPS } from "../../../constants/common/enum/common"

const MultiTripV2 = ({
    selectedCity,
    mtSegList,
    getCityText,
    mtError,
    flightWay,
    isNeedFocusSecond,
    handleDelMtSeg,
    onDateChange,
    onSearchDateChange,
    showValidateErrorTip,
    onAddMtSeg,
    canAddMoreMtSeg,
    exchangeMultiCity,
    handleEnter,
    id,
    workingDays,
    changePoi,
    clearPoiItem,
    changeCountrySource,
    changeHotSearchSwitch,
    changeArrivalCountry,
    showWarn,
    cancelFocusMtSecondDCity,
    clickHotCountry,
    switchToHotCountryUbt,
    onCityMouseDown,
    onDateMouseDown
}) => {
    return (
        <div>
            {Object.keys(mtSegList).map((seg, i) => (
                <div className="form-line" key={i}>
                    <span className="num">{i + 1}</span>
                    <City
                        mode="D"
                        label="出发城市  中文/拼音/英文"
                        name={`mtDCity${i + 1}`}
                        width="100%"
                        isShowDepartCityDefault={i >= 1}
                        prevACity={
                            i >= 1 && selectedCity.get(`mtACity${i}`)
                                ? selectedCity.get(`mtACity${i}`)
                                : null
                        }
                        curCity={selectedCity.get(`mtDCity${i + 1}`)}
                        cityText={getCityText(
                            selectedCity,
                            `mtDCity${i + 1}`
                        )}
                        focusSecond={i === 1 && isNeedFocusSecond}
                        errorMsg={
                            showValidateErrorTip && mtError.get(i) && mtError.getIn([i, "dCity"])
                        }
                        flightWay={flightWay}
                        // isFocused={searchProps.getIn([
                        //     `showCityPicker`,
                        //     `mtDCity${i + 1}`
                        // ])}
                        id={id}
                        handleEnter={handleEnter}
                        changePoi={changePoi}
                        clearPoiItem={clearPoiItem}
                        changeCountrySource={changeCountrySource}
                        changeHotSearchSwitch={changeHotSearchSwitch}
                        changeArrivalCountry={changeArrivalCountry}
                        showWarn={showWarn}
                        cancelFocusMtSecondDCity={cancelFocusMtSecondDCity}
                        clickHotCountry={clickHotCountry}
                        switchToHotCountryUbt={switchToHotCountryUbt}
                        onMouseDown={(mode)=>onCityMouseDown(mode,i)}
                        selectorStyle={{top: '0px', left: '0px'}}
                    />
                    <div className="switch-btn" u_key='switch_poi' u_remark={`点击交换POI[index:${i}]`} onClick={() => exchangeMultiCity(i)}><i className="ico-switch"></i></div>
                    <City
                        mode="A"
                        label="到达城市  中文/拼音/英文"
                        name={`mtACity${i + 1}`}
                        width="100%"
                        cityText={getCityText(
                            selectedCity,
                            `mtACity${i + 1}`
                        )}
                        errorMsg={
                            showValidateErrorTip && mtError.get(i) && mtError.getIn([i, "aCity"])
                        }
                        flightWay={flightWay}
                        // isFocused={searchProps.getIn([
                        //     `showCityPicker`,
                        //     `mtACity${i + 1}`
                        // ])}
                        id={id}
                        handleEnter={handleEnter}
                        changePoi={changePoi}
                        clearPoiItem={clearPoiItem}
                        changeCountrySource={changeCountrySource}
                        changeHotSearchSwitch={changeHotSearchSwitch}
                        changeArrivalCountry={changeArrivalCountry}
                        showWarn={showWarn}
                        cancelFocusMtSecondDCity={cancelFocusMtSecondDCity}
                        clickHotCountry={clickHotCountry}
                        switchToHotCountryUbt={switchToHotCountryUbt}
                        onMouseDown={(mode)=>onCityMouseDown(mode,i)}
                        selectorStyle={{top: '0px', left: '0px'}}
                    />
                    <div
                        className={`form-item-v2 flt-date ${showValidateErrorTip &&
                            mtError.get(i) &&
                            mtError.getIn([i, "dDate"]) ? "form-error-v2" : ""}`}
                            onMouseDown={()=>onDateMouseDown(i)}
                    >
                        <i className="ico-calendar-gray"></i>
                        <ModifyDateInfo
                            label="出发日期 yyyy-mm-dd"
                            date={mtSegList[i].StartDate}
                            timeZone={mtSegList[i].TimeZone}
                            start={mtSegList[i - 1] && mtSegList[i - 1].StartDate}
                            end={mtSegList[i].StartDate}
                            min={
                                mtSegList[i - 1] && (moment(mtSegList[i - 1].StartDate).diff(moment(),'days') < 0 ? moment() : (mtSegList[i - 1].StartDate || moment())  )
                            }
                            max={moment().add(1, 'year')}
                            onDateChange={onDateChange(
                                `mtStartDate${i + 1}`,
                                onSearchDateChange
                            )}
                            errorMsg={
                                showValidateErrorTip &&
                                mtError.get(i) &&
                                mtError.getIn([i, "dDate"])
                            }
                            title={DATE_PICKER_TITLE['MT'].value.depart}
                            footerTips={DATE_PICKER_FOOTER_TIPS.TIME.value['MT'].depart}
                            flightWay="MT"
                            mutilTripNumber={i}
                            showWeek={true}
                            workingDays={workingDays}
                            
                        />
                    </div>
                    {i >= 2 ? (
                        <i
                            className="ico-close-b"
                            onClick={handleDelMtSeg(i)}
                        />
                    ) : null}
                </div>
            ))}

            {canAddMoreMtSeg ? (
                <div className="add-trip" onClick={onAddMtSeg}><i className="ico-plus"></i>再加一程</div>
            ) : null}
        </div>
    )
}

export default MultiTripV2
