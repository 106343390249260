import React from 'react';
import PropTypes from 'prop-types'
import SelectPassengers from './selectPassengers'


export default class SelectPassengersContainer extends React.Component {

    shouldComponentUpdate(nextProps) {
        if(this.props.adultCnt !== nextProps.adultCnt || this.props.childCnt !== nextProps.childCnt ||
        this.props.infantCnt !== nextProps.infantCnt)
            return true
        return false
    }
    render() {
        return <SelectPassengers {...this.props} />
    }
}
SelectPassengersContainer.propTypes = {
    adultCnt:PropTypes.number,
    childCnt:PropTypes.number,
    infantCnt:PropTypes.number,
    incrementPassenger:PropTypes.func,
    decrementPassenger:PropTypes.func,
}
SelectPassengersContainer.defaultProps = {
    adultCnt:1,
    childCnt:0,
    infantCnt:0,
    incrementPassenger:()=>{},
    decrementPassenger:()=>{}
}
